@import '../../../../styles/customMediaQueries.css';

/* Desktop */
.root {
  /* Size */
  width: 100%;
  height: var(--topbarHeightCategiries);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 36px;
  position: relative;

  /* fill */
  background-color: var(--colorWhite);

  /* shadows */
  box-shadow: var(--boxShadowLight);

  @media (--viewportLarge) {
    padding-left: 270px;
  }
  border-top: 1px solid var(--colorGrey100);
  padding-top: 8px;
}

/* logo */
.logoLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);
}
.logoLink:hover {
  border-bottom: 4px solid var(--marketplaceColor);
  border-radius: 0;
}

/* Search */
.searchLink {
  flex-grow: 1;
  height: 100%;
  margin-right: 24px;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--colorGrey100);

  @media (--viewportLarge) {
    padding-left: 0;
  }
}

.search {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
  }
}

.topbarSearchWithLeftPadding {
  padding-left: 24px;
  height: var(--topbarHeightCategiries);

  @media (--viewportLarge) {
    padding-left: 34px;
  }
}

/* Create listing (CTA for providers) */
.createListingLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: var(--marketplaceColor);

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  margin: 0;

  &:hover {
    color: var(--marketplaceColorDark);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
}

.topbarDesktopLabel {
  composes: textSmall from global;
  display: inline-block;
  margin: 6px 0;
  text-decoration: inherit;
}

.createListing {
  composes: topbarDesktopLabel;
}

/* Inbox */
.inboxLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  height: 100%;
  padding: 0 12px 0 12px;
  margin: 0;

  &:hover {
    color: var(--colorBlack);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
}

.inbox {
  composes: topbarDesktopLabel;
  position: relative;
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 2px;
  right: -9px;

  /* Style: red dot */
  background-color: var(--colorFail);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0 12px 0 12px;

  &:hover {
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }

  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatar {
  margin: 16px 0;
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
}

/* Authentication */
.signupLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  color: var(--colorGrey700);

  &:hover {
    color: var(--colorBlack);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }
}

.loginLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  color: var(--colorGrey700);

  &:hover {
    color: var(--colorBlack);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }
}

.signup,
.login {
  composes: textSmall from global;

  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.profileSettingsLink,
.yourListingsLink {
  composes: textSmall from global;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--colorGrey700);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorBlack);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  display: inline;

  /* Text size should be inherited */
  text-decoration: none;

  /* Font is specific to this component */
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: var(--colorGrey300);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);

  /* Borders */
  border: none;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--colorGrey100);

  &:hover {
    cursor: pointer;
    color: var(--colorBlack);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.currentPage {
  color: var(--colorBlack);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--colorBlack);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--colorBlack);
    }
  }
}

.categoryLabel {
  text-align: center;
  font-size: 0.75rem;
  fill: var(--colorGrey700);
  color: var(--colorGrey700);
  transition: var(--transitionStyleButton);
}
.categoryLabel:hover {
  color: var(--colorBlack);
  border-bottom: 2px solid var(--colorGrey600);
}
.categoryLabel:active {
  transform: scale(0.95);

}
.categoryLabel:hover svg {
  fill: var(--colorGrey700);
}

.categoryImage{
  width: 28px;
}
.searchModalButtonContainer{
  padding: 0 0 0 20px;
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}
.categoryIconsContainner{
  flex-grow: 1;
  overflow: hidden;
  text-align: center;
  padding: 0 30px;
  position: relative;
  width: 100%;
}

.categoryScrollerContainner{
  overflow: hidden;
  height: 60px;
}
.categoryCeneterContainner{
  display: inline-block;
}

.scrollerContainerRight{
  width: 40px;
  height: 50px;
  position: absolute;
  right: 28px;
  background-image: linear-gradient(to right,rgb(255 255 255/0),white 40px);
  z-index: 1;
  transition: all 0.3s;
}
.scrollerContainerLeft{
  width: 40px;
  height: 50px;
  position: absolute;
  left: 40;
  background-image: linear-gradient(to left,rgb(255 255 255/0),white 40px);
  z-index: 1;
  transition: all 0.3s;
}

.categoriesList{
  flex-grow: 1;
  display: grid;
  justify-content: center;
  grid-auto-flow: column;
  align-items: flex-end;
}
.searchModalButton{
  display: block;
  padding: 0 20px;
  margin: 10px 0px;
}
.categoryIcon{
  display: block;
  width: 20px;
  height: 20px;
  padding: 0 20px;
  margin: 10px 0px;
}
.categoryContainner{
  display: block;
  min-width: 44px;
  /* margin: 0 20px; */
  margin: 0 10px;
}

/* arrows  */
.arrowHead {
  fill: var(--colorGrey300);
  stroke: var( --colorGrey300);
}

.navLeft,
.navRight {
  position: absolute;
  /* width: 10vw; */
  /* height: 100%; */
  border: 0;
  padding: 0;
  z-index: 1;
  top: calc(50% - 15px);
  transform: scale(0.9);

  /* center content */
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: rgba(0,0,0,0.08);
  
  &:hover {
    cursor: pointer;
    transform: scale(1);

    & .navArrowWrapper {
      background-color: rgba(255, 255, 255, 1);
    }
    & .arrowHead {
      fill: var(--colorGrey500);
      stroke: var(--colorGrey500);
    }
  }

  &:active {
    transform: scale(0.9);
  }
}

.navLeft {
  left: -15px;
  justify-content: flex-start;
}

.navRight {
  right: -15px;
  justify-content: flex-end;
}

.navArrowWrapper {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transition: all 0.25s;

  background-color: transparent;

  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;

  @media (--viewportMedium) {
    background-color: rgba(255, 255, 255, 0.8);
  }
}
.categoryLabel{
}
.categoryLabel svg{
  height: 30px;
}
.active .categoryLabel{
  color: var(--colorBlack);
  border-bottom: 2px solid var(--colorBlack);
}

.showMapSwitcher{
  display: inline-flex;
  flex-direction: row;
  border: solid 1px var(--colorGrey100);
  border-radius: 18px;
  height: 40px;
  width: 150px;
  align-items: center;
  vertical-align: middle;
  padding: 0 10px;
  justify-content: space-between;
  margin: 10px 0px;
}
.mapSwitcherContainer{
  padding-left: 10px;
  padding-top: 4px;
}